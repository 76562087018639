import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				AutoAce Car Rentals
			</title>
			<meta name={"description"} content={"Ваше путешествие - наш приоритет"} />
			<meta property={"og:title"} content={"AutoAce Car Rentals"} />
			<meta property={"og:description"} content={"Ваше путешествие - наш приоритет"} />
			<meta property={"og:image"} content={"https://zephyriumparadise.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://zephyriumparadise.com/img/346243634.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://zephyriumparadise.com/img/346243634.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://zephyriumparadise.com/img/346243634.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://zephyriumparadise.com/img/346243634.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://zephyriumparadise.com/img/346243634.png"} />
			<meta name={"msapplication-TileImage"} content={"https://zephyriumparadise.com/img/346243634.png"} />
			<meta name={"msapplication-TileColor"} content={"https://zephyriumparadise.com/img/346243634.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="flex-start"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Комплексные услуги по аренде автомобилей
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
				В компании AutoAce Car Rentals мы предлагаем широкий спектр услуг, чтобы удовлетворить все ваши потребности в путешествиях. Нужен ли вам автомобиль на день, неделю или более длительный срок, мы найдем для вас идеальное решение. Наша приверженность качеству и удовлетворенности клиентов гарантирует, что ваш опыт работы с нами будет гладким, удобным и приятным.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://zephyriumparadise.com/img/4.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-8" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					width="100%"
				>
					<Image
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						src="https://zephyriumparadise.com/img/5.jpg"
						object-fit="cover"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-order="1"
				justify-content="flex-start"
				align-items="flex-start"
				padding="24px 24px 24px 24px"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--light"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Наш автопарк
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--light" font="--lead" lg-text-align="center">
				Наш обширный автопарк включает в себя множество автомобилей на любой вкус и требования. У нас есть все: от компактных автомобилей для езды по городу до просторных внедорожников для семейных поездок. Каждый автомобиль в нашем парке проходит тщательный уход и регулярное техническое обслуживание, чтобы гарантировать вашу безопасность и комфорт. Изучите наш ассортимент, чтобы найти идеальный автомобиль для вашего путешествия.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			Готовы сесть за руль вместе с AutoAce?
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			Испытайте лучшие услуги по аренде автомобилей с AutoAce. Наш широкий спектр услуг в сочетании с нашей приверженностью к удовлетворению потребностей клиентов гарантирует, что вы получите беспроблемный и приятный опыт аренды.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				text-decoration-line="initial"
				href="/contact"
			>
				Связаться с нами
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});